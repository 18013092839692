import React from 'react';
import {
  ErrorBoundary as ReactErrorBoundary,
  ErrorBoundaryPropsWithComponent,
} from 'react-error-boundary';
import { Language } from '@dnb/locale';
import ErrorFallback from './ErrorFallback';

interface ErrorBoundaryProps extends Partial<ErrorBoundaryPropsWithComponent> {
  children: React.ReactNode;
  locale?: Language;
  showRetryButton?: boolean;
}

export default function ErrorBoundary({
  children,
  onReset,
  ...props
}: ErrorBoundaryProps) {
  return (
    <ReactErrorBoundary
      FallbackComponent={(fallbackProps) => (
        <ErrorFallback {...fallbackProps} {...props} />
      )}
      onReset={
        typeof onReset === 'function' ? onReset : () => window.location.reload()
      }
      {...props}
    >
      {children}
    </ReactErrorBoundary>
  );
}
