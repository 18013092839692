import React from 'react';
import { Section, Tabs, Heading, Icon } from '@dnb/eufemia';
import { check } from '@dnb/eufemia/icons';
import { Provider } from '@dnb/eufemia/shared';
import { useSigningObject } from '../../store/useSigningObject';
import { useStep } from '../../store/useStep';
import { useTabs } from '../../store/useTabs';
import './main-container.css';

const MainContainer: React.FC = ({ children }) => {
  const { step, setStep } = useStep();
  const { signingObject } = useSigningObject();
  const { tabStatus } = useTabs();

  const renderTab = (title: string, done: boolean) => (
    <span>
      {title} {done && <Icon icon={check} />}
    </span>
  );

  return (
    <Provider value={{ Section: { style_type: 'white' } }}>
      <Section className="main">
        <Heading
          style={{
            paddingTop: 'var(--spacing-large)',
            paddingBottom: 'var(--spacing-large)',
          }}
          size="x-large"
        >
          New eSigning task
        </Heading>
        <Tabs
          data={{
            first: {
              title: renderTab('1. Customer', tabStatus.customerTab),
              key: 0,
            },
            second: {
              title: renderTab('2. Product', tabStatus.productTab),
              key: 1,
              disabled: signingObject.customerId === '' ? true : false,
            },
            third: {
              title: renderTab('3. Documents', tabStatus.documentsTab),
              key: 2,
              disabled: signingObject.product === '' ? true : false,
            },
            fourth: {
              title: renderTab('4. Signers', tabStatus.signersTab),
              key: 3,
              disabled: signingObject.product === '' ? true : false,
            },
            fifth: {
              title: renderTab('5. Contact person', tabStatus.contactPersonTab),
              key: 4,
              disabled: signingObject.product === '' ? true : false,
            },
            sixth: {
              title: '6. Summary',
              key: 5,
              disabled: signingObject.product === '' ? true : false,
            },
          }}
          selected_key={step}
          on_click={({ selected_key }) => {
            setStep(parseInt(selected_key));
          }}
        />
        <Provider value={{ Section: { style_type: 'lavender' } }}>
          <Section>{children}</Section>
        </Provider>
      </Section>
    </Provider>
  );
};

export default MainContainer;
