import create from 'zustand';
import { SigningObject } from '../utils/types';

type signingObjectStateType = {
  signingObject: SigningObject;
  setSigningObject: (signingObject: SigningObject) => void;
};

export const useSigningObject = create<signingObjectStateType>((set) => ({
  signingObject: {
    id: '',
    numberOfSigners: 1,
    customerName: '',
    customerId: '',
    daysToLive: 0,
    product: '',
    documents: [],
    signers: [],
    contactPerson: {
      firstName: '',
      lastName: '',
      userId: '',
      email: '',
    },
    tasks: [],
  },
  setSigningObject: (signingObject: SigningObject) =>
    set((state) => ({ signingObject: (state.signingObject = signingObject) })),
}));
