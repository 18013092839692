import React from 'react';
import { wrapAppElement } from '@dnb/app';
import ErrorBoundary from '@dnb/error-boundary';
import Head from '@dnb/helmet';
import MainContainer from './components/MainContainer/MainContainer';

// import styles from './styles.module.scss';

/**
 * Use the "wrapAppElement" to provide providers to every page,
 * but only for pages within this application.
 *
 * "wrapAppElement" needs an "appName" defined in the plugin options within the root application.
 * More details: /docs/WrapAppElement.md
 *
 */
export const wrapPageElement = wrapAppElement(({ element }) => {
  element = (
    <ErrorBoundary>
      <Head>
        <title>DNB - eSigning portal</title>
      </Head>
      <MainContainer>{element}</MainContainer>
    </ErrorBoundary>
  );

  return element;
});
