import create from 'zustand';
import { Tabs } from '../utils/types';

type tabsStateType = {
  tabStatus: Tabs;
  setTabStatus: (tabStatus: Tabs) => void;
};

export const useTabs = create<tabsStateType>((set) => ({
  tabStatus: {
    customerTab: false,
    productTab: false,
    documentsTab: false,
    signersTab: false,
    contactPersonTab: false,
    summaryTab: false,
  },
  setTabStatus: (tabStatus: Tabs) =>
    set((state) => ({ tabStatus: (state.tabStatus = tabStatus) })),
}));
