module.exports = [{
      plugin: require('../../../../libs/dnb-components/layout/node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../libs/dnb-components/layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"DNB","short_name":"DNB","start_url":"/","icon":"./public/apple-touch-icon.png","icons":[{"src":"./android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"./android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"theme_color":"#007272","background_color":"#007272","display":"minimal-ui","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"68b678f24da500df7415b27c6c5d34a6"},
    },{
      plugin: require('../../../../libs/dnb-gatsby-themes/app/gatsby-browser.js'),
      options: {"plugins":[],"usedByApp":"esigning-portal","__appDirectory":"/builds/dnb/dcf/dnb-web/apps/identity/esigning-portal"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
