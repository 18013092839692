import create from 'zustand';

type stepStateType = {
  step: number;
  setStep: (step: number) => void;
  nextStep: () => void;
};

export const useStep = create<stepStateType>((set) => ({
  step: 0,
  setStep: (step: number) => {
    set((state) => ({ step: (state.step = step) }));
  },
  nextStep: () => {
    set((state) => ({ step: state.step + 1 }));
  },
}));
