import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { FormattedMessage } from 'react-intl';
import { IntlProvider } from 'react-intl';
import { FormStatus, Button } from '@dnb/eufemia/components';
import { P } from '@dnb/eufemia/elements';
import { useLanguage, Language } from '@dnb/locale';
import translationKeys from '../../lib/translationKeys';
import translations from '../../lib/translations';

interface CustomFallbackProps extends FallbackProps {
  message?: string | null;
  title?: string | null;
  showRetryButton?: boolean;
  state?: 'warn' | 'error' | null;
  locale?: Language;
}

export default function ErrorFallback({
  error,
  resetErrorBoundary,
  showRetryButton = false,
  message = null,
  title = null,
  state = null,
  locale: localeFromProps,
  ...props
}: CustomFallbackProps): JSX.Element {
  const { locale: DNBLocale } = useLanguage();
  const locale = localeFromProps || DNBLocale;

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore FormStatus want undefined, but we set it to null. Passing down undefined can give unwanted side-effect.
    <FormStatus data-testid="dnb-error-boundary" state={state} {...props}>
      <IntlProvider
        locale={locale}
        key={locale}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore we are missing the test-TEST locale, in order to work
        messages={translations[locale]}
      >
        <P>
          {title ? title + ' – ' : null}
          {message || error.message}
        </P>
        {showRetryButton && resetErrorBoundary && (
          <Button
            variant="secondary"
            on_click={resetErrorBoundary}
            data-testid="dnb-error-boundary-tryagain"
          >
            <FormattedMessage
              id={translationKeys.dnb_error_boundary_tryagain}
            />
          </Button>
        )}
      </IntlProvider>
    </FormStatus>
  );
}
